.horizontal-menu-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cards-container {
  display: flex;
  overflow: hidden;
  transition: transform 0.5s ease; /* Adjust the transition duration and timing function as needed */
}

.cards-container > div {
  flex: 0 0 auto;
}