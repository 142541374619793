.slick-slide>div {
    margin: 0 15px;
}

/* .slick-prev {
    position: absolute;
    background: black;
    transform: translate(100%, -50%);
}

.slick-next {
    background: black;
    transform: translate(-100%, 60%);
} */